//====================================================
//  Function: Process
//====================================================
function componentProcess() {
  if ($('.js-process-trigger').length) {
    $('.js-process-trigger').click(function () {
      var inst = $(this)
      var processBlock = inst.closest('.js-process')

      if (inst.hasClass(classIsActive)) {
        // inst.toggleClass(classIsActive)
      } else {
        processBlock
          .find('.js-process-trigger.' + classIsActive + '')
          .removeClass(classIsActive)
        inst.addClass(classIsActive)
      }
      return false
    })
  }
}
