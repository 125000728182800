//====================================================
//  Function: Slider
//====================================================
function componentSwiper() {
  clientsSlider()
  blogSlider()
}

function clientsSlider() {
  if ($('.js-slider-clients').length) {
    var inst = $('.js-slider-clients')

    var mySwiper = new Swiper(inst, {
      slidesPerView: 1,
      spaceBetween: 30,
      lazy: true,
      loop: true,
      speed: 600,
      autoplay: {
        delay: 2000,
        disableOnInteraction: true,
      },
      breakpoints: {
        200: {
          slidesPerView: 3,
        },
        576: {
          slidesPerView: 4,
        },
        768: {
          slidesPerView: 5,
        },
        992: {
          slidesPerView: 5,
        },
        1200: {
          slidesPerView: 6,
        },
      },
    })
  }
}

function blogSlider() {
  if ($('.js-slider-blog').length) {
    var inst = $('.js-slider-blog'),
      swiperBlock = inst.closest('.js-slider-group'),
      swiperArrowPrev = swiperBlock.find('.js-swiper-arrow-prev'),
      swiperArrowNext = swiperBlock.find('.js-swiper-arrow-next')

    var mySwiper = new Swiper(inst, {
      slidesPerView: 1,
      spaceBetween: 60,
      lazy: true,
      loop: false,
      speed: 600,
      // Navigation arrows
      navigation: {
        nextEl: swiperArrowNext,
        prevEl: swiperArrowPrev,
      },
      breakpoints: {
        200: {
          // freeMode: true,
          slidesPerView: 1,
        },
        576: {
          // freeMode: true,
          slidesPerView: 2,
        },
        768: {
          // freeMode: true,
          slidesPerView: 2,
        },
        992: {
          // freeMode: true,
          slidesPerView: 3,
        },
        1200: {
          // freeMode: true,
          slidesPerView: 3,
        },
      },
    })
  }
}
