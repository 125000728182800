// --- GLOBAL VARIABLES --- //
var $window = $(window)
var $document = $(document)
var $header = $('.header')
var bpSmall = 771
var bpMedium = 992
var bpLarge = 1200
var classIsActive = 'is-active'
var classIsDisabled = 'is-disabled'
var classIsLast = 'is-last'
var classHasError = 'has-error'
var classIsLoading = 'is-loading'
var classIsOpened = 'is-opened'
var classIsSelected = 'is-selected'
var classIsCollapse = 'is-collapse'
var classIsAnimated = 'is-animated'
var $this = null
var $containers = $('html,body')

// --- COMPONENTS, PARTS --- //
$(document).ready(function () {
	// ---  COMPONENTS --- //
	// Modal
	componentModal()
	// Select
	componentSelect()
	// Parallax
	componentParallax()

	// Process
	componentProcess()
	// Swiper
	componentSwiper()
	// Textarea Resize
	componentTextareaResize()
	// Google Maps
	componentGoogleMaps()
	// Navigation
	componentFullscreenNavigation()
	// File upload
	componentFileUpload()
	// Validate forms
	componentValidateForms()
	// // ---  PART --- //
	// Placeholder
	partPlaceholder()
	// Scroll to
	partScrollTo()
	// Scroll Resize
	partScrollResize()

	if (!Modernizr.backgroundcliptext) {
		$('body').addClass('no-background-clip-text')
	}
})

var resizeTimer

// --- SCROLL EVENT --- //
$(document).scroll(function () {
	if (resizeTimer) {
		window.cancelAnimationFrame(resizeTimer)
	}

	resizeTimer = window.requestAnimationFrame(function () {
		partScrollResize()
	})
})

// --- RESIZE EVENT --- //
$(window).resize(function () {
	if (resizeTimer) {
		window.cancelAnimationFrame(resizeTimer)
	}

	resizeTimer = window.requestAnimationFrame(function () {
		partScrollResize()
	})
})

// --- LOAD EVENT --- //
$(window).bind('load', function () {
	// Dots
	// partDots()

	// Page Loader
	$('body').addClass(classIsActive)

	if ($('.process__dot--1.js-process-trigger').length) {
		$('.process__dot--1.js-process-trigger').addClass(classIsActive)
	}

	// Cookies bar
	// setTimeout(function () {
	//   $('#cookies-bar').addClass(classIsActive)
	// }, 2000)
})
